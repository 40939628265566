export default {
	bind(el, binding, vnode) {
		el.mouseDownEventHandler = (event) => {
			el.mouseStartTarget = event.target;
		};

		el.mouseUpEventHandler = (event) => {
			// check that mousedown and mouseup event was outside the el and his children
			if (!(el === event.target || el.contains(event.target))
				&& !(el === el.mouseStartTarget || el.contains(el.mouseStartTarget))
				&& !event.target.closest('*[data-ignore-directive]')
				&& !event.target.closest('.el-popper')
				&& (el.mouseStartTarget && el.mouseStartTarget.contains(event.target)))
				setTimeout(() => { // add timeout to avoid calling the function before the click event is over
					vnode.context[binding.expression](event);
				});
		};

		setTimeout(() => {
			document.body.addEventListener('mousedown', el.mouseDownEventHandler);
			document.body.addEventListener('mouseup', el.mouseUpEventHandler);
			document.body.querySelectorAll('iframe').forEach(iframe => {
				if (iframe?.contentDocument?.body) {
					iframe.contentDocument.body.addEventListener('mousedown', el.mouseDownEventHandler);
					iframe.contentDocument.body.addEventListener('mouseup', el.mouseUpEventHandler);
				}
			});
		});
	},
	unbind(el) {
		document.body.removeEventListener('mousedown', el.mouseDownEventHandler);
		document.body.removeEventListener('mouseup', el.mouseUpEventHandler);
		document.body.querySelectorAll('iframe').forEach(iframe => {
			if (iframe?.contentDocument?.body) {
				iframe.contentDocument.body.removeEventListener('mousedown', el.mouseDownEventHandler);
				iframe.contentDocument.body.removeEventListener('mouseup', el.mouseUpEventHandler);
			}
		});
	}
};